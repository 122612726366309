<template>
  <b-modal
    id="editarConta"
    hide-footer
    centered
    size="xl"
    :title="`Editar Conta: ${form.nome}`"
  >
    <b-card>
      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
        <div
          class="alert-body"
          v-for="(error, index) in getErrors.messageArray"
          :key="index"
        >
          {{ error[0] }}
        </div>

        <div class="alert-body">{{ getErrors.message }}</div>
      </b-alert>

      <div class="d-flex">
        <feather-icon icon="BoxIcon" size="19" />
        <h4 class="mb-2 ml-50">Dados da Conta</h4>
      </div>

      <validation-observer ref="editarContaValidation">
        <b-form class="mt-1">
          <b-row>
            <b-col cols="12" sm="12" md="6" lg="6">
              <b-form-group label="Nome *" label-for="nome">
                <validation-provider
                  #default="{ errors }"
                  name="Nome"
                  rules="required|max:60"
                >
                  <b-form-input
                    id="nome"
                    v-model="form.nome"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="3" lg="3">
              <b-form-group label="Agência" label-for="num_agencia">
                <validation-provider #default="{ errors }" name="Agencia">
                  <cleave
                    id="num_agencia"
                    v-model="form.num_agencia"
                    class="form-control"
                    :raw="false"
                    :options="options.num_agencia"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6" md="3" lg="3">
              <b-form-group label="Número da Conta" label-for="num_conta">
                <validation-provider
                  #default="{ errors }"
                  name="Número da Conta"
                >
                  <cleave
                    id="num_conta"
                    v-model="form.num_conta"
                    class="form-control"
                    :raw="false"
                    :options="options.num_conta"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="submit"
                @click="gravaAlteracoes"
              >
                <b-spinner small v-if="spinner" />
                <span v-if="spinner">Aguarde...</span>
                <span v-else>Salvar Alterações</span>
              </b-button>

              <b-button variant="outline-secondary" @click="fecharModal">
                Fechar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BCard,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BAlert,
  BFormSelect,
  VBModal,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import Cleave from "vue-cleave-component";
import store from "@/store";
// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  props: ["form", "contas"],
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BAlert,
    BFormSelect,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
  },

  data() {
    return {
      required,
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      spinner: false,
      submit: false,
      errors: false,
      options: {
        num_agencia: {
          delimiters: ["-"],
          blocks: [4, 1],
          numericOnly: true,
          delimiterLazyShow: true,
          rawValueTrimPrefix: true,
        },
        num_conta: {
          delimiters: ["-"],
          blocks: [8, 1],
          numericOnly: true,
          delimiterLazyShow: true,
          rawValueTrimPrefix: true,
        },
      },
    };
  },

  methods: {
    gravaAlteracoes() {
      this.$refs.editarContaValidation.validate().then((success) => {
        if (success) {
          this.spinner = true;
          this.submit = true;
          let index = this.contas.indexOf(this.form);

          this.$http
            .put("adm/contas/editar/" + this.form.id, this.form)
            .then((res) => {
              let resposta = {
                index: index,
                dados: this.form,
              };

              store.commit("contas/EDITAR_CONTA", resposta);

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Conta alterada com sucesso!",
                  icon: "InfoIcon",
                  variant: "success",
                },
              });

              this.fecharModal();
            })
            .catch((error) => {
              this.getErrors = this.$responseMessages.getMessage(error, null);
            })
            .finally(() => {
              this.spinner = false;
              this.submit = false;
            });
        }
      });
    },
    fecharModal() {
      this.$bvModal.hide("editarConta");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
