<template>
  <div>
    <b-row>
      <b-col md="6" sm="8" class="my-1">
        <b-form-group label-for="filterInput" class="mb-0">
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Procurar"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          striped     
          hover
          responsive
          :items="contas"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
        >
          <template #row-details="row">
            <b-card>
              <div class="d-flex mb-2">
                <feather-icon class="text-success" icon="BoxIcon" size="19" />
                <h5 class="mb-0 ml-50 text-success">
                  {{ row.item.nome }}
                </h5>
              </div>

              <b-row class="mb-2" v-if="row.item.id">
                <b-col md="6" class="mb-1">
                  <strong> Agência: </strong>
                  {{ row.item.num_agencia }}
                </b-col>

                <b-col md="6" class="mb-1">
                  <strong> Número da Conta: </strong>
                  {{ row.item.num_conta }}
                </b-col>
              </b-row>

              <hr />

              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Fechar Detalhes
              </b-button>
            </b-card>
          </template>

          <template #cell(opcoes)="data">
            <b-button
              class="btn-icon"
              style="padding: 2px"
              variant="flat-secondary"
              v-model="data.detailsShowing"
              @click="data.toggleDetails"
            >
              <feather-icon
                :class="data.detailsShowing ? 'text-primary' : 'text-default'"
                icon="EyeIcon"
                size="16"
              />
            </b-button>

            <b-button
              class="btn-icon"
              variant="flat-secondary"
              style="padding: 2px"
              @click="editarConta(data.item)"
            >
              <feather-icon icon="Edit2Icon" size="16" />
            </b-button>

            <b-button
              class="btn-icon text-danger"
              variant="flat-secondary"
              style="padding: 2px"
              @click="excluirConta(data.item.id, data.index)"
            >
              <feather-icon icon="Trash2Icon" size="16" />
            </b-button>
          </template>
        </b-table>
      </b-col>

      <ModalEditarConta
        ref="EditarContaComponent"
        :form="editar"
        :contas="contas"
      />
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapState } from "vuex";
import ModalEditarConta from "./ModalEditarConta.vue";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BFormCheckbox,
    ModalEditarConta,
    ToastificationContent,
  },
  data() {
    return {
      spinner: 0,
      todosSelecionados: false,
      selecionavel: false,
      selected: [],
      ContaEditar: [],
      ContaIndex: "",
      contasExcluidas: [],
      configEditar: [],
      verExcluidos: false,
      editar: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: ["nome"],
      boxTwo: "",

      fields: [
        {
          key: "nome",
          label: "Nome",
          thStyle: { width: "70%" },
          sortable: true,
        },
        { key: "opcoes", label: "Opções", sortable: false },
      ],
      cards: false,
    };
  },
  filters: {
    formatMoeda(val) {
      if (!val) val = 0;
      return parseFloat(val).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
  computed: {
    ...mapState({
      contas: (state) => state.contas.contas,
      // user: state => state.user.user
    }),

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  mounted() {
    this.buscaDados();
  },

  methods: {
    editarConta(conta) {
      this.editar = conta;
      this.$bvModal.show("editarConta");
    },
    buscaDados() {
      this.$store.dispatch("contas/listaContas");
    },
    excluirConta(id, index) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Deseja realmente excluir essa Conta ?", {
          title: "Excluir Conta",
          size: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((valor) => {
          if (valor) {
            this.$http
              .delete("adm/contas/excluir/" + id)
              .then((res) => {
                this.$store.commit("contas/DELETAR_CONTA", index);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Conta excluída com sucesso!",
                    icon: "InfoIcon",
                    variant: "success",
                  },
                });
              })
              .catch((error) => {
                this.$responseMessages.getMessage(error, null);
              });
          }
        });
    },
  },
};
</script>
<style scoped>
.b-table-row-selected {
  background-color: #0b5661;
  color: #fff;
}

.espacoTd {
  width: 30px;
}
</style>
