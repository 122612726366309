<template>
  <div style="height: inherit">
    <b-row class="mb-1">
      <b-col cols="4">
        <b-button
          style="margin-right: 5px"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="gradient-success"
          @click="cadastrarConta"
          size="sm"
        >
          <feather-icon icon="PlusIcon" class="mr-75" />
          Adicionar Conta
        </b-button>

        <b-button size="sm" type="submit" variant="gradient-primary" style="margin-right: 5px">
          <feather-icon icon="PrinterIcon"></feather-icon>
        </b-button>

        <b-button size="sm" type="submit" variant="gradient-primary">
          <feather-icon icon="FileTextIcon"></feather-icon>
        </b-button>
      </b-col>
    </b-row>
    <b-card>
    <ListaContas />
    </b-card>
    <ModalCadastrarConta />
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BAlert,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import ListaContas from "./ListaContas.vue";
import ModalCadastrarConta from "./ModalCadastrarConta.vue";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BAlert,

   ListaContas,
    ModalCadastrarConta,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },

  computed: {
    ...mapState({
     // user: (state) => state.user.user,
      contas: (state) => state.contas.contas,
    }),
  },

  methods: {
    cadastrarConta() {
      this.$bvModal.show("cadastrarConta");
    },
  },

  name: "ContaPrincipal.vue",
};
</script>

<style scoped>
</style>
